import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import "./App.css";

function App() {
 const [windowDimensions, setWindowDimensions] = useState({
  width: window.innerWidth,
  height: window.innerHeight,
 });

 useEffect(() => {
  const timer = setTimeout(() => {
   window.location.replace("https://diplomautbildning.se");
  }, 1);

  // Uppdatera fönsterdimensioner vid resize
  const handleResize = () => {
   setWindowDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
   });
  };

  window.addEventListener("resize", handleResize);

  // Cleanup
  return () => {
   clearTimeout(timer);
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 return (
  <div className="App">
   <Confetti
    colors={["#4e52a2", "#A24E7C", "#4EA274", "#EBD25D"]}
    width={windowDimensions.width}
    height={windowDimensions.height}
    numberOfPieces={200}
    recycle={true}
   />
   <div className="logo-container">
    <div style={{ position: "relative" }}>
     <a href="https://diplomautbildning.se">
      <img width="100%" src="./logo.svg" alt="Logo" className="logo" />
     </a>
     <p className="domain-text">.se</p>
    </div>
    <div className="btn-container">
     <a className="btn" href="https://diplomautbildning.se">
      Gå till Diploma
     </a>
    </div>
   </div>
  </div>
 );
}

export default App;
